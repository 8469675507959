@import '../../variables.scss';

.list {
  list-style: none;

  @include medium {
    display: flex;
  }

  h4 {
    margin-bottom: 0;
  }

  .event {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.75rem;

    @include mediumMax {
      width: 100%;
    }

    @include medium {
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  p {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.button {
  align-self: center;
  border-radius: 5px;
  height: 40px;
  border: 1px solid #5886e8;
  background-color: $primary-color;
  color: white;
  transition: 0.3s;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  flex-grow: 0;

  &:hover {
    background-color: $primary-color-transparent;
  }

  &:active {
    box-shadow: none;
  }
}

.subInfoList {
  width: 100%;


}

.subInfo {
  color: rgba(0, 0, 0, 0.7);
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.staffBox {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.singleStaffBox {
  padding: 0 0.5rem;

  &:nth-child(2) {
    padding: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }
}

.portrait {
  margin: 0.5rem 0;
  max-width: 150px;
  height: auto;

  @include smallMax {
    max-width: 100px;
  }
}

.eventBox {
  margin: 0;
}

.finePrint {
  margin-top: 0.5rem;
  font-size: 0.75em;
}