@import 'normalize.scss';
@import 'variables.scss';

html {
  font-size: $base-font-size;
  @include mediumMax {
    font-size: $mobile-font-size;
  }
}

h1, h2 {
  margin-bottom: 1rem;
  letter-spacing: -0.5px;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  margin-bottom: 0.75rem;
}

h4, h5 {
  margin-bottom: 0.5rem;
  letter-spacing: +0.5px;
}

h1, h2, h3, h4, h5 {
  font-family: var(--primary-font);
  color: var(--primary-color);
}

body {
  margin: 0;
  font-family: var(--secondary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  margin-top: -60px;
  padding-bottom: 2rem;
  align-items: center;
  @include medium {
    margin-top: -100px;
  }
}

#root {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preamble {
  margin-bottom: 1rem;
}

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.6);
}

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.6);
}

// Overrides
svg.MuiSvgIcon-root {
  display: block;
}
