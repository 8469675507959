@import '../../variables.scss';
.main {
    padding: 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    @include mediumMax {
        h2 {
            margin-bottom: 0;
        }
    }
}

.eventsContainer {
    max-width: 900px;
    align-self: stretch;
    margin: 0 auto 1rem;
}

.message {
    max-width: 400px;
    margin-top: 1rem;
    @include mediumMax {
        margin-bottom: 1rem;
    }
}
